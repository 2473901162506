import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tile from "../components/tile"

const LabTests = () => (
	<Layout>
		<SEO title="Lupus lab tests - diagnosing and monitoring" />
		<h1>Lupus lab tests</h1>
		<p>
			In addition to the presence of clinical symptoms, a diagnosis of lupus is
			usually confirmed by laboratory tests. These same tests are used for
			monitoring lupus.
		</p>
		<h2>Tests to help in diagnosis of lupus</h2>
		<h3>ANA (Antinuclear antibody)</h3>
		<p>
			An ANA is a test for the presence of abnormal antibodies. It is very
			sensitive, not well standardised and while positive in most lupus
			patients, it will also be positive in many a person that does not have
			lupus (including healthy persons). The test is used in reaching a lupus
			diagnosis, but has little value as a repeat test.
		</p>
		<h3>ENA (Extractable nuclear antigen antibodies)</h3>
		<p>
			A test for multiple antibodies against extractable nuclear antigens
			(proteins). Antibodies against Sm protein are quite specific for lupus,
			while antibodies against other nuclear proteins (eg SSA or RNP protein)
			can help define subsets of lupus. The test is helpful in reaching an lupus
			diagnosis, but has little value as a repeat test.
		</p>
		<h3>Antiphospholipid antibody</h3>
		<p>
			This antibody is involved in blood clotting. More blood clots form when
			this measure is high. The test is helpful in reaching an lupus diagnosis,
			but has little value as a repeat test.
		</p>
		<h3>Anti-dsDNA antibody</h3>
		<p>
			These antibodies are generally more specific for lupus than the ANA test,
			but are detected in only 60-70 % of lupus patients (depending on the
			method of detection).
		</p>
		<h2>Tests to help monitor disease activity in lupus over time</h2>
		<p>
			<b>Note - The frequency of testing depends on the severity of lupus.</b>
		</p>
		<h3>Complement Proteins</h3>
		<p>
			Complement Proteins are proteins in the blood that fight infections. This
			test measures levels of these proteins in the blood. In lupus, levels can
			be low as antibodies use these proteins to intensify inflammation and the
			test is used to see how severe the disease is or to see if a treatment is
			working.
		</p>
		<h3>ESR</h3>
		<p>
			Erythrocyte (ir-RITH-ro-cite) Sedimentation Rate (ESR) is a blood test
			that reflects a number of proteins in the blood that increase with
			inflammation.
		</p>
		<h3>CRP (C reactive protein)</h3>
		<p>
			CRP is also protein that is produced to fight off infections and also
			rises with sterile inflammation as occurs in lupus
		</p>
		<h3>Creatinine</h3>
		<p>
			Creatinine is a breakdown product of muscle tissue that is excreted by the
			kidneys into the urine. If the kidneys do not work well enough, creatinine
			levels in the blood will therefore rise.
		</p>
		<h3>Proteinuria</h3>
		<p>
			Proteinuria is the amount of protein in the urine and is normally minimal.
			Abnormal proteinuria is often the first signal that lupus is affecting the
			kidneys as they start to leak more protein in the urine.
		</p>
		<h3>LFT’s (Liver function tests)</h3>
		<p>
			LFT’s or Liver function tests is the general term for a group of tests
			that check how your liver is working. As the liver gets rid of many lupus
			drugs, it is important to check these liver enzymes regularly.
		</p>
		<h3>Hematocrit</h3>
		<p>
			This is the percent of blood that is made up of red blood cells. Low
			levels can be due to active lupus where red blood cells get destroyed or
			due to side effects of drugs for lupus.
		</p>
		<h3>WBC (White blood cell count)</h3>
		<p>
			The number and subtypes of white blood cells present in the blood. Very
			important in fighting off infections. Tests can give an indication how
			your lupus is faring and if drugs for lupus are affecting the blood
			production too much.
		</p>
		<h3>Anti-dsDNA antibody</h3>
		<p>
			This is one of the few autoantibodies that fluctuate over time in lupus
			and increasing anti-dsDNA levels can indicate an impending flare in
			subgroups of patients , however the association between anti-dsDNA levels
			and disease activity is not absolute.
		</p>
		<h3>Platelet count</h3>
		<p>
			Small blood cells that start the process of blood clotting. Low levels can
			be due to active lupus where platelets get destroyed or due to side
			effects of drugs for lupus
		</p>
		<h2>Other test to monitor health in SLE</h2>
		<h3>Bone Density Test</h3>
		<p>Bone density is a test of how solid and how strong your bones are.</p>
		<h3>EKG</h3>
		<p>
			Electrocardiogram. An EKG is a recording of the electric activity of the
			heart.
		</p>
		<h3>Echocardiogram</h3>
		<p>
			An echocardiogram is a test that provides imaging of the heart using
			ultrasound technique. This allows visualisation of the heart structure and
			assesses pump function.
		</p>
		<h3>X-Ray</h3>
		<p>A picture of the bones and other parts inside of the body.</p>
	</Layout>
)

export default LabTests
